.loadingContainer {
  height: 100px;
  text-align: center;
  padding-top: 30px;
}

.title {
  font-weight: 600;
  font-size: 26px;
}
