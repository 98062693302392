.content {
  max-width: 350px;
  text-align: center;
  margin: auto;
}

.pContent {
  max-width: 250px;
  text-align: center;
  margin: auto;
}
