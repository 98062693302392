.loginContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c5777;
}

.loginForm {
  width: 328px;
  margin-top: -100px;
}
