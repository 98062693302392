body {
  margin: 0;
  background: #f0f2f5 !important;
}

.container {
  padding: 24px;
  background: #fff;
  width: 100%;
  margin: 0px auto 16px auto;
  box-shadow: 0 0.8rem 0.8rem 0 rgb(17 17 17 / 8%);
}

.container-sm {
  padding: 24px;
  background: #fff;
  max-width: 500px;
  margin: 0px auto 16px auto;
  box-shadow: 0 0.8rem 0.8rem 0 rgb(17 17 17 / 8%);
}

.w-100 {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-highlight {
  background: rgba(22, 135, 129, 0.8);
  color: white !important;
  padding: 2px 4px 2px 2px;
}

.text-link {
  color: #1890ff;
}

.f-12 {
  font-size: 12px !important;
}

.f-18 {
  font-size: 18px !important;
}

.m-h-a {
  margin-left: 0px;
  margin-right: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-20 {
  margin-right: 20px;
}

.pt-0 {
  padding-top: 0px;
}

.pt-12 {
  padding-top: 12px !important;
}

.pb-0 {
  padding-bottom: 0px;
}
