@import "~antd/dist/antd.less";

// @primary-color: #fccc11;
@menu-item-color: #f0f2f5;
@menu-item-active-border-width: 5px;
@layout-body-background: #f0f2f5;
@menu-highlight-color: #fccc11;
@btn-primary-color: black;
@btn-primary-bg: #fccc11;
// @divider-color: rgba(252, 204, 17, 0.2);
@divider-color: rgba(0, 0, 0, 6%);
// #168781

.prefixIcon {
  color: #1c5777;
  font-size: 15px;
}

.dividerText {
  color: @divider-color;
}

div.ant-typography-edit-content {
  left: 0px;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #1890ff;
  border-color: #1890ff;
}

.txt-primary {
  color: @primary-color;
}

