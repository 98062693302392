.reportSection {
  padding: 10px 0px 10px 0px;
}

.perc {
  padding: 3px 10px 3px 10px;
  border-radius: 4px;
  display: inline-block;
}

.percSelected {
  background-color: #168781;
  color: white;
  padding: 3px 10px 3px 10px;
  border-radius: 4px;
  display: inline-block;
}

.loadingContainer {
  height: 100px;
  text-align: center;
  padding-top: 30px;
}

.title {
  font-weight: 600;
  font-size: 26px;
}

.subTitle {
  font-weight: 400;
  font-size: 17px;
}

.docBtn {
  margin-right: 15px;
  margin-bottom: 15px;
}

.link {
  cursor: pointer;
  color: #1890ff;
}

.not {
  color: rgba(0, 0, 0, 0.45);
  font-style: italic;
}
